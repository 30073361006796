export const subscribeToPlan = /* GraphQL */ `
  mutation SubscribeToPlan($input: SubscribeToPlanVersionInput!) {
    subscribeToPlan(input: $input) {
      ... on SubscribeToPlanVersionValidationFailed {
        errorMessage
        errorCode
      }
      ... on InvalidField {
        errorMessage
        fieldName
        errorCode
      }
      ... on AccountNotFound {
        errorMessage
        providerId
        errorCode
        accountId
      }
      ... on PlanVersionIsNotAssignable {
        errorMessage
        providerId
        planVersionId
        errorCode
      }
      ... on PlanVersionNotFound {
        errorMessage
        planVersionId
        providerId
        errorCode
      }
      ... on SubscribeToPlanVersionPayload {
        __typename
        subscribedPlanVersion {
          to
          from
          planVersion {
            version
            state
            id
          }
        }
        account {
          customData
          id
          activePlanVersions {
            from
            to
            planVersion {
              version
              state
              id
            }
          }
          archivedPlanVersions {
            to
            from
            planVersion {
              version
              state
              id
            }
          }
          balance {
            version
            value
            customData
          }
          inactivePlanVersions {
            to
            planVersion {
              state
              version
              id
            }
            from
          }
        }
      }
    }
  }
`
