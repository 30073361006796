import moment from 'moment'
import { axiosInstance as axios, axiosGraphQlRequest } from '../../utils/axiosInstances'
import {
  createDeviceQuery,
  deleteDeviceQuery,
  getDeviceQuery
} from '../modules/queries'
import { createDevice, deleteDevice } from './utils/persistence/mutations'
import { graphQLUrl, persistenceUrl } from '@/store/common'
import { buildGraphQlPayload } from '@/utils/graphql_helpers'
import { buildReadableAllocationsOrBalances } from './utils/unifiedBalances'
import { getDevices } from './utils/persistence/queries'
import { subscribeToPlan } from './utils/totogi/mutations'
import { N28_SUBSCRIPTION_URL, N28_SUBSCRIPTIONS_CALLBACK_URL } from '@/utils/constants'

export default {
  namespaced: true,
  state: {
    demoDeviceEdrEdges: [],
    devices: []
  },
  getters: {},
  mutations: {
    setDemoAccount (state, account) {
      state.demoAccount = account
    },
    setDemoAccountBalance (state, balance) {
      state.demoAccountBalance = balance
    },
    setDemoAccountAllocations (state, allocations) {
      state.demoAccountAllocations = allocations
    },
    setDemoAccountPlans (state, plans) {
      state.demoAccountPlans = plans
    },
    setDemoDevice (state, device) {
      state.demoDevice = device
    },
    setDemoDeviceEdrEdges (state, newValue) {
      state.demoDeviceEdrEdges = newValue
    },
    setDemoDeviceRefreshInterval (state, newValue) {
      state.demoDeviceRefreshInterval = newValue
    },
    setDevices (state, newValue) {
      state.devices = newValue
    },
    setN28NotificationRefreshInterval (state, newValue) {
      state.n28NotificationRefreshInterval = newValue
    }
  },
  actions: {
    async delete (context, { deviceId, accountId }) {
      const result = await axios.post(
        graphQLUrl,
        deleteDeviceQuery(
          deviceId,
          accountId,
          context.rootState.providerId
        ), {
          headers: {
            Authorization: `${context.rootState.idToken}`
          }
        }
      )
      if (!result?.data?.data?.deleteDevice?.deletedDeviceId) {
        return
      }
      await axios.post(
        persistenceUrl,
        buildGraphQlPayload(deleteDevice, {
          input: {
            accountId,
            deviceId,
            deviceName: '-',
            providerId: context.rootState.providerId
          }
        }),
        context.rootGetters.graphQLHeaders
      )
    },
    async create (context, { deviceId, accountId }) {
      const result = await axios.post(
        graphQLUrl,
        createDeviceQuery(
          deviceId,
          accountId,
          context.rootState.providerId
        ),
        {
          headers: {
            Authorization: `${context.rootState.idToken}`
          }
        }
      )
      if (!result?.data?.data?.createDevice?.device) {
        return
      }
      await axios.post(
        persistenceUrl,
        buildGraphQlPayload(createDevice, {
          input: {
            accountId,
            deviceId,
            providerId: context.rootState.providerId
          }
        }),
        context.rootGetters.graphQLHeaders
      )
    },
    async getDevice (context, { deviceId }) {
      const result = (await axios.post(
        graphQLUrl,
        getDeviceQuery(
          deviceId,
          context.rootState.providerId
        ),
        context.rootGetters.graphQLHeaders
      )).data.data.getDevice
      if (result.errorCode) {
        throw result
      }
      return result
    },
    async getDemoDevice (context, { deviceId }) {
      console.log(`Loading demo device ${deviceId}`)
      try {
        context.commit('setDemoDevice', await context.dispatch('getDevice', { deviceId }))
      } catch (err) {
        if (err.errorCode) {
          throw err
        }
      }
      if (!context.rootState.settings.settingsLoaded) {
        await context.dispatch('settings/loadSettings', null, { root: true })
      }
      const device = context.state.demoDevice
      context.commit('setDemoAccount', device.account)
      const accountBalance = await context.dispatch('getAccount', { accountId: device.account.id }, { root: true })
      accountBalance.serviceBalances = await buildReadableAllocationsOrBalances(accountBalance.serviceBalances, 'balance')
      context.commit('setDemoAccountBalance', accountBalance)
      if (!context.rootState.allAvailablePlanInformation || !context.rootState.allAvailablePlanInformation.length) {
        await context.dispatch('planVersions/getPlanInformation', null, { root: true })
      }
      await context.dispatch('loadDemoDevicePlans')
      await context.dispatch('resetEdrs', null, { root: true })
      context.dispatch('refreshDemoDeviceBalanceAndInfo')
      return device
    },
    async loadDemoDevicePlans (context, options) {
      const payload = { accountId: context.state.demoAccount.id, includeAllocations: true }
      if (!options || !options.refresh) {
        payload.account = context.state.demoAccount
        console.log('No refresh')
      } else {
        console.log('Refresh')
      }
      console.log('Loading demo device plans')
      const { accountPlans, allocations } = await context.dispatch('account/retrieveAccountPlansAndInfo', payload, { root: true })
      context.commit('setDemoAccountPlans', accountPlans)
      context.commit('setDemoAccountAllocations', allocations)
      console.log('Demo Device Active Plans')
      console.table(accountPlans)
      console.log('Demo Device Allocations')
      /*
        Example allocations
        {
          id: "data.1GB.7e884ec2-c607-401d-9a26-08564268b303",
          name: "Data",
          planVersionId: "85fe518d-0b6b-440a-845e-880a9e33f39f",
          readableValue: "1024 MB",
          subscriptionStartedAt: Moment,
          type: "Data",
          uuid: "7e884ec2-c607-401d-9a26-08564268b303",
          value: 1073741824
        }
        {
          id: "voice-all-net.86acc42b-981d-4985-87f5-4f93eae5be91",
          name: "Voice All Net",
          planVersionId: "bcc2559b-eea6-4528-9aea-83e4af9adc5b",
          readableValue: "Unlimited",
          subscriptionStartedAt: Moment,
          type: "Voice",
          uuid: "86acc42b-981d-4985-87f5-4f93eae5be91",
          value: null
        }
      */
      console.table(allocations)
    },
    async resubscribePlan (context, { planSubscriptionId, planVersionId }) {
      console.log('Resubscribing to plan')
      await context.dispatch('clearDemoDeviceRefresh')
      await context.dispatch('clearN28NotificationSubscription')
      await context.dispatch('loadDemoDevicePlans', { refresh: true })
      console.log(`Cancelling plan ${planSubscriptionId} - ${planVersionId}`)
      await context.dispatch('account/invokeCancelPlanSubscription', {
        accountId: context.state.demoAccount.id,
        planSubscriptionId
      }, { root: true })
      await new Promise(resolve => setTimeout(resolve, 1000))
      await axiosGraphQlRequest(
        graphQLUrl,
        subscribeToPlan,
        {
          input: {
            accountId: context.state.demoAccount.id,
            providerId: context.rootState.providerId,
            planVersionId: planVersionId
          }
        },
        context.rootGetters.graphQLHeaders
      )
      await context.dispatch('loadDemoDevicePlans', { refresh: true })
      console.log('Resubscribing to plan complete')
      context.dispatch('refreshDemoDeviceBalanceAndInfo')
      await context.dispatch('initiateDemoDeviceRefresh')
    },
    async provisionNewPlanDemoDevice (context) {
      console.log('Provisoning new demo device')
      await context.dispatch('clearDemoDeviceRefresh')
      await context.dispatch('clearN28NotificationSubscription')
      await context.dispatch('loadDemoDevicePlans', { refresh: true })
      const latestPlanSubscription = await context.dispatch('account/latestPlanSubscription', { activePlans: context.state.demoAccountPlans }, { root: true })
      console.log('Latest susbcription', latestPlanSubscription)
      if (latestPlanSubscription) {
        console.log('Cancelling plan')
        console.log(context.state.demoAccount, context.state.demoAccount.id, latestPlanSubscription.planVersionId, latestPlanSubscription, context.state.demoAccountPlans)
        await context.dispatch('account/cancelPlanSubscription', {
          accountId: context.state.demoAccount.id,
          planVersionId: latestPlanSubscription.planVersionId,
          activePlans: context.state.demoAccountPlans
        }, { root: true })
        await new Promise(resolve => setTimeout(resolve, 1000))
      }
      await context.dispatch('account/subscribeToCurrentPlanVersion', { accountId: context.state.demoAccount.id }, { root: true })
      await context.dispatch('loadDemoDevicePlans', { refresh: true })
      console.log('Provisioning new plan complete', context.state.demoAccountAllocations.map(a => a.subscriptionStartedAt.format()), context.state.demoAccountPlans.map(p => p.planSubscriptionId))
      context.dispatch('refreshDemoDeviceBalanceAndInfo')
      await context.dispatch('initiateDemoDeviceRefresh')
    },
    async refreshDemoDeviceBalanceAndInfo (context) {
      console.log('Refreshing Demo Device information')
      const accountBalance = await context.dispatch('getAccount', { accountId: context.state.demoDevice.account.id }, { root: true })
      accountBalance.serviceBalances = await buildReadableAllocationsOrBalances(accountBalance.serviceBalances, 'balance')
      /*
        Example Service balances:
        {
          id: "voice-all-net.86acc42b-981d-4985-87f5-4f93eae5be91",
          name: "Voice All Net",
          readableValue: "0 mins",
          type: "Voice",
          uuid: "86acc42b-981d-4985-87f5-4f93eae5be91",
          value: "0"
        }
        {
          "id": "data.1GB.7e884ec2-c607-401d-9a26-08564268b303",
          "name": "Data",
          "readableValue": "1024 MB",
          "type": "Data",
          "uuid": "7e884ec2-c607-401d-9a26-08564268b303",
          "value": "1073741824"
        }
      */
      for (const activePlanVersion of accountBalance.activePlanVersions) {
        for (const planService of activePlanVersion.planVersion.planServices) {
          for (const serviceBalance of accountBalance.serviceBalances) {
            if (planService.balanceName === serviceBalance.id) {
              serviceBalance.planId = activePlanVersion.planVersion.plan.id
              serviceBalance.planName = activePlanVersion.planVersion.plan.name
              serviceBalance.planSubscriptionId = activePlanVersion.planSubscriptionId
              serviceBalance.from = activePlanVersion.from
              serviceBalance.to = activePlanVersion.to
              serviceBalance.planVersionName = activePlanVersion.planVersion.version
              serviceBalance.planVersionId = activePlanVersion.planVersion.id
              break
            }
          }
        }
      }
      console.log('Account Balances')
      console.table(accountBalance.serviceBalances.concat([{ name: 'Monetary', value: accountBalance.monetaryBalance }]))
      console.log('Active plan versions')
      console.table(accountBalance.activePlanVersions)
      context.commit('setDemoAccountBalance', accountBalance)
      context.dispatch('notifications/update', { accountId: context.state.demoAccount.id, deviceId: context.state.demoDevice.id }, { root: true })
      const mappedEdrEdges = await context.dispatch('getAndSummarizeEdrs', { deviceId: context.state.demoDevice.id }, { root: true })
      context.commit('setDemoDeviceEdrEdges', mappedEdrEdges)
    },
    async subscribeN28Notifications (context) {
      const result = await axios.post(N28_SUBSCRIPTION_URL, {
        supi: context.state.demoDevice.id,
        notifUri: N28_SUBSCRIPTIONS_CALLBACK_URL,
        expiry: moment().add(10, 'minute').utc().toISOString()
      }, {
        headers: {
          Authorization: `${context.rootState.idToken}`
        }
      })
      console.log('N28 subscription complete', result)
    },
    async resetDemoDevice (context) {
      console.log('Resetting Demo Device')
      context.commit('setDemoDevice', null)
      context.commit('setDemoAccount', null)
      context.commit('setDemoAccountBalance', null)
      context.commit('setDemoAccountPlans', null)
    },
    async initiateDemoDeviceRefresh (context) {
      context.dispatch('clearDemoDeviceRefresh')
      context.dispatch('clearN28NotificationSubscription')
      context.commit('setDemoDeviceRefreshInterval', setInterval(() => {
        console.log('Demo device refresh interval')
        context.dispatch('refreshDemoDeviceBalanceAndInfo')
      }, 6000))
      context.dispatch('subscribeN28Notifications')
      context.commit('setN28NotificationRefreshInterval', setInterval(() => {
        console.log('N28 Notification subscription interval')
        context.dispatch('subscribeN28Notifications')
      }, 600000))
    },
    async clearDemoDeviceRefresh (context) {
      if (context.state.demoDeviceRefreshInterval) {
        clearInterval(context.state.demoDeviceRefreshInterval)
        context.commit('setDemoDeviceRefreshInterval', null)
      }
    },
    async clearN28NotificationSubscription (context) {
      if (context.state.n28NotificationRefreshInterval) {
        clearInterval(context.state.n28NotificationRefreshInterval)
        context.commit('setN28NotificationRefreshInterval', null)
      }
    },
    async loadDevices (context) {
      const devices = (await axios.post(
        persistenceUrl,
        buildGraphQlPayload(getDevices, {
          providerId: context.rootState.providerId
        }),
        context.rootGetters.graphQLHeaders
      )).data.data.getDevices.devices
      devices.forEach((device, index) => {
        if (!device.deviceName || device.deviceName === '-') {
          const account = (context.rootState.account.accounts || []).filter(account => account.accountId === device.accountId)[0]
          device.deviceName = `Dev-${Number(index) + 1}`
          device.account = account
        }
      })
      context.commit('setDevices', devices.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1))
    }
  }
}
