import { axiosInstance as axios } from '../../utils/axiosInstances'
import { graphQLUrl, notificationsEventBusArn } from '@/store/common'
import { getMyProviderConfig, updateProviderConfigEventBusArn, getFieldMappingsQuery } from '../modules/queries'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {
    setProviderConfigEventBusArn (state, eventBusArn) {
      state.providerConfigEventBusArn = eventBusArn
    }
  },
  actions: {
    async getMyProviderConfig (context) {
      const idToken = await context.rootState.idToken
      const providerConfigResponse = await axios.post(
        graphQLUrl,
        getMyProviderConfig(), {
          headers: {
            Authorization: `${idToken}`
          }
        }
      )
      console.log('My provider config response:')
      console.log(providerConfigResponse)
      try {
        const providerConfigData = providerConfigResponse.data.data.getMyProviderConfig
        if (providerConfigData.__typename !== 'ProviderConfig') {
          throw new Error('Error while fetching ProviderConfig!')
        }
        const homeNetworks = providerConfigData.homeNetworks
        const eventBusArn = providerConfigData.eventBusArn
        console.log('homeNetworks:', homeNetworks)
        console.log('Event Bus ARN', eventBusArn)
        let mcc = homeNetworks.substr(0, 3)
        let mnc = homeNetworks.substr(3)
        console.log('mcc:', mcc)
        console.log('mnc:', mnc)
        context.commit('updateMcc', mcc, { root: true })
        context.commit('updateMnc', mnc, { root: true })
        context.commit('setProviderConfigEventBusArn', eventBusArn)
        console.log('Checking for telcos')
        if (mcc && mnc) {
          mcc = String(mcc)
          mnc = String(mnc)
          if (mnc.length < 2) {
            mnc = `0${mnc}`
          }
          const telcoCountries = context.rootState.settings.telcosByCountries
          for (const country of Object.keys(telcoCountries)) {
            const telcos = telcoCountries[country]
            for (const telco of telcos.telcosWithCode) {
              if (telco.mcc === String(mcc) && telco.mnc === String(mnc)) {
                console.log('Found telco for default mcc/mnc', telco.label)
                context.commit('settings/setTelcoCountry', country, { root: true })
                context.commit('settings/setTelco', telco.label, { root: true })
              }
            }
          }
        }
      } catch (e) {
        console.error('Setting MCC and MNC failed: ', e.toString())
      }
      const fieldMappings = (await axios.post(
        graphQLUrl,
        getFieldMappingsQuery(context.rootState.providerId, 25),
        context.rootGetters.graphQLHeaders
      )).data.data.getFieldMappings
      console.log('Field mappings found', fieldMappings)
    },
    async setNotificationEventBus (context) {
      await context.dispatch('getMyProviderConfig')
      if (!context.state.providerConfigEventBusArn) {
        console.log('Setting event bus ARN', notificationsEventBusArn)
        const idToken = await context.rootState.idToken
        await axios.post(
          graphQLUrl,
          updateProviderConfigEventBusArn(notificationsEventBusArn), {
            headers: {
              Authorization: `${idToken}`
            }
          }
        )
        context.commit('setProviderConfigEventBusArn', notificationsEventBusArn)
      }
      console.log('event bus ARN', context.state.providerConfigEventBusArn)
    }
  }
}
