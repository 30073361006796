<template>
<CContainer class="py-5">
  <CToaster placement="top-center">
    <CToast color="warning" :autohide="true" delay="3000" :key="index" v-for="(toast, index) in pageToasts">
      <CToastBody>{{ toast }}</CToastBody>
    </CToast>
  </CToaster>
  <div class="fs-1 fw-semibold pb-4 text-start d-flex justify-content-start w-100">
    <div class="pe-3">
      Account Details
    </div>
    <div class="flex-grow-1 align-self-center">
      <CProgress v-if="entityInputModalProcessing && entitiesBeingProcessed.length">
        <CProgressBar :value="processedEntitiesPercent">{{ processedEntitiesPercent }}%</CProgressBar>
      </CProgress>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-end w-100 pb-3">
    <div class="px-1 flex-grow-1">
      <div class="d-flex">
        <div>
          <CInputGroup>
            <CInputGroupText>Filter by Account name</CInputGroupText>
            <CFormInput v-model="accountNameSearchText" placeholder="Search" />
            <TotButton color="secondary" variant="outline" :disabled="performingAction || loadingAccountsDevices" @click="loadAccountsDevices">
              <template #initial>Search</template>
            </TotButton>
          </CInputGroup>
        </div>
      </div>
    </div>
    <div class="px-1">
      <TotButton :disabled="performingAction || loadingAccountsDevices" @click="loadAccountsDevices">
        <template #initial>Refresh</template>
      </TotButton>
    </div>
    <div class="px-1">
      <TotButton :disabled="performingAction" :loading="creatingAccount" @click="initiateCreateAccount">
        <template #loading>Creating Account&nbsp;</template>
        <template #initial>Create Account</template>
      </TotButton>
    </div>
    <div class="px-1">
      <TotButton :c-tooltip="'Create devices for one or more accounts'" :disabled="performingAction" :loading="creatingDevices" @click="initiateCreateDevices">
        <template #initial>Create Device</template>
      </TotButton>
    </div>
    <div class="px-1">
      <CDropdown>
        <CDropdownToggle color="primary" :disabled="performingAction">Actions</CDropdownToggle>
        <CDropdownMenu>
          <!--<CDropdownItem href="#">Delete All</CDropdownItem>-->
          <CDropdownItem href="#" @click="initiateCreditAccounts">Credit Account</CDropdownItem>
          <CDropdownItem href="#" @click="initiatePlanSubscription">Subscribe to Plan</CDropdownItem>
          <!--<CDropdownItem href="#">Delete Devices</CDropdownItem>-->
          <!--<CDropdownItem href="#">Delete Accounts</CDropdownItem>-->
        </CDropdownMenu>
      </CDropdown>
    </div>
    <div class="px-1">
      <TotButton :disabled="performingAction" @click="openDeviceDemo">
        <template #initial>Test 5G call</template>
      </TotButton>
    </div>
  </div>
  <CTable>
    <CTableHead>
      <CTableRow>
        <CTableHeaderCell scope="col" class="text-start">
          <CFormCheck :checked="allAccountsSelected" @click="toggleAllAccountSelection" :disabled="performingAction" />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" class="text-start">Account ID</CTableHeaderCell>
        <CTableHeaderCell scope="col" class="text-start">Account Name</CTableHeaderCell>
        <CTableHeaderCell scope="col" class="text-start">Parent</CTableHeaderCell>
        <CTableHeaderCell scope="col" class="text-start">Device ID</CTableHeaderCell>
        <CTableHeaderCell scope="col" class="text-center">Active Plans</CTableHeaderCell>
        <CTableHeaderCell scope="col" class="text-center">Credit</CTableHeaderCell>
        <CTableHeaderCell scope="col" class="text-start">Activated on</CTableHeaderCell>
        <CTableHeaderCell scope="col" class="text-center">Churn Score</CTableHeaderCell>
      </CTableRow>
    </CTableHead>
    <CTableBody>
      <CTableRow
      v-for="account in accounts"
      :key="account.accountId"
      >
        <CTableDataCell class="text-start">
          <CFormCheck :checked="account.selected" @click="toggleAccountSelection(account)" :disabled="performingAction" />
        </CTableDataCell>
        <CTableDataCell class="text-start">
          <TotBadge :copyValue="account.accountId">{{account.accountIdText}}</TotBadge>
        </CTableDataCell>
        <CTableDataCell class="text-start">
          {{account.accountName}}
        </CTableDataCell>
        <CTableDataCell class="text-start">
          {{account.parentId}}
        </CTableDataCell>
        <CTableDataCell class="text-start">
          <span v-if="account.deviceId">
            <TotBadge :copyValue="account.deviceId">{{account.deviceIdText}}</TotBadge>
          </span>
        </CTableDataCell>
        <CTableDataCell class="text-center">
          <CTooltip :content="account.subscribedPlansText" placement="top">
            <template #toggler="{ on }">
              <span v-on="on" class="d-inline-block" style="min-width: 25px;">
                {{account.subscribedPlans ? account.subscribedPlans.length : 0}}
              </span>
            </template>
          </CTooltip>
        </CTableDataCell>
        <CTableDataCell class="text-center">
          <span v-if="account.creditLimit === 0">Prepaid</span>
          <span v-else>{{account.creditLimit}}</span>
        </CTableDataCell>
        <CTableDataCell class="text-start">
          {{account.createdAtText}}
        </CTableDataCell>
        <CTableDataCell class="text-center">
          {{account.churnScoreText}}
        </CTableDataCell>
      </CTableRow>
    </CTableBody>
  </CTable>
  <div class="d-flex py-2 justify-content-end">
    <div>
      <TotButton v-if="nextPagePresent" color="link" :disabled="performingAction || loadingAccountsDevices" @click="loadNextPage">
        <template #initial>Next Page</template>
      </TotButton>
    </div>
  </div>
  <TotInputModal
    :error="entityInputModalError"
    :showModal="entityInputModalShowModal"
    :placeholder="entityInputModalPlaceholder"
    :helpText="entityInputModalHelpText"
    :processingAction="entityInputModalProcessing"
    @modal-closed="handleEntityInputModalClose"
    @modal-submitted="handleEntityInputModalSubmitted"
  >
    <template #title>{{ entityInputModalTitle }}</template>
    <template #modalProcessingText>{{ entityInputModalProcessingText }}</template>
    <template #modalSubmitText>{{ entityInputModalSubmitText }}</template>
  </TotInputModal>
  <PlanSelectorModal :showModal="showPlanSelectorModal" @modal-closed="planSelectorModalClosed" @plan-selected="subscribeToPlan" @overrides-changed="overridesChanged"  />
</CContainer>
</template>

<script>
import TotBadge from './common/tot-badge.vue'
import TotButton from './common/tot-button.vue'
import TotInputModal from './common/tot-input-modal'
import PlanSelectorModal from './PlanSelectorModal.vue'
import { uuid } from 'vue-uuid'

export default {
  name: 'AccountDetails',
  data () {
    return {
      accountNameSearchText: '',
      creatingAccount: false,
      creatingDevices: false,
      subscribingAccount: false,
      accountSelection: '',
      allAccountsSelectedValue: false,
      loadingAccountsDevices: false,
      performingAction: false,
      entityInputModalTitle: '',
      entityInputModalShowModal: false,
      entityInputModalError: null,
      entityInputModalPlaceholder: '',
      entityInputModalHelpText: '',
      entityInputModalProcessing: false,
      entityInputModalProcessingText: '',
      entityInputModalSubmitText: '',
      entityInputModalType: '',
      entitiesBeingProcessed: [],
      entitiesProcessed: [],
      pageToasts: [],
      showPlanSelectorModal: false,
      planOverrides: []
    }
  },
  computed: {
    accounts () {
      return this.$store.state.account.accountsDevices
    },
    allAccountsSelected: {
      get () {
        return this.allAccountsSelectedValue
      },
      set (newValue) {
        this.allAccountsSelectedValue = newValue
      }
    },
    processedEntitiesPercent () {
      console.log(Math.floor(this.entitiesProcessedCount / this.entitiesBeingProcessedCount))
      if (this.entitiesBeingProcessedCount > 0) {
        return Math.floor(this.entitiesProcessedCount * 100 / this.entitiesBeingProcessedCount)
      }
      return 0
    },
    entitiesProcessedCount () {
      return this.entitiesProcessed.length
    },
    entitiesBeingProcessedCount () {
      return this.entitiesBeingProcessed.length
    },
    nextPagePresent () {
      return this.$store.state.account.accountsDevicesNextToken
    },
    selectedDeviceAccounts () {
      const accounts = []
      this.accounts.forEach(account => {
        if (account.selected && account.deviceId) {
          accounts.push(account)
        }
      })
      return accounts
    },
    uniqueSelectedAccountIds () {
      const accountIds = []
      this.accounts.forEach(account => {
        if (account.selected && accountIds.indexOf(account.accountId) < 0) {
          accountIds.push(account.accountId)
        }
      })
      return accountIds
    }
  },
  mounted () {
    if (!this.$store.state.allAvailablePlanInformation || !this.$store.state.allAvailablePlanInformation.length) {
      this.$store.dispatch('planVersions/getPlanInformation')
    }
    this.$store.dispatch('account/loadAccountsDevices', { syncAccounts: true })
  },
  methods: {
    loadNextPage () {
      this.loadAccountsDevices(this.$store.state.account.accountsDevicesNextToken)
    },
    async loadAccountsDevices (nextToken) {
      if ((typeof nextToken) !== 'string') {
        nextToken = null
      }
      this.loadingAccountsDevices = true
      await this.$store.dispatch('account/loadAccountsDevices', { syncAccounts: true, accountName: this.accountNameSearchText, nextToken })
      this.loadingAccountsDevices = false
    },
    changeAccountSelection (value) {
      this.accountSelection = value
    },
    async subscribeAccount (accountId) {
      this.subscribingAccount = true
      await this.$store.dispatch('account/subscribeToCurrentPlanVersion', { accountId: accountId })
      this.subscribingAccount = false
    },
    initiateCreateAccount () {
      this.entityInputModalTitle = 'Enter a name for the account'
      this.entityInputModalShowModal = true
      this.entityInputModalError = null
      this.entityInputModalPlaceholder = 'Account Name'
      this.entityInputModalHelpText = 'Name is required'
      this.entityInputModalProcessing = false
      this.entityInputModalProcessingText = 'Creating Account'
      this.entityInputModalSubmitText = 'Create Account'
      this.entityInputModalType = 'CreateAccount'
      this.entitiesBeingProcessed = []
      this.entitiesProcessed = []
    },
    async createAccount (accountName) {
      if (!accountName) {
        this.entityInputModalError = 'Please enter a name for the account'
        return
      }
      this.creatingAccount = true
      this.performingAction = true
      this.entityInputModalError = ''
      this.entityInputModalProcessing = true
      const accountId = uuid.v4()
      await this.$store.dispatch('account/create', { accountId: accountId, accountName: accountName })
      await this.$store.dispatch('account/credit', { accountId: accountId, amount: 1000 })
      this.loadAccountsDevices()
      this.entityInputModalProcessing = false
      this.performingAction = false
      this.creatingAccount = false
      this.entityInputModalShowModal = false
    },
    initiateCreateDevices () {
      const accountIds = this.uniqueSelectedAccountIds
      if (accountIds.length < 1) {
        this.pageToasts.push('Please select atleast one account to create a device')
        return
      }
      this.entityInputModalTitle = 'Enter a name for the devices'
      this.entityInputModalShowModal = true
      this.entityInputModalError = null
      this.entityInputModalPlaceholder = 'Device(s) Name'
      this.entityInputModalHelpText = 'Leave blank for an alias name to be used'
      this.entityInputModalProcessing = false
      this.entityInputModalProcessingText = 'Creating Devices'
      this.entityInputModalSubmitText = 'Create Devices'
      this.entityInputModalType = 'CreateDevices'
      this.entitiesBeingProcessed = []
      this.entitiesProcessed = []
    },
    async createDevices (deviceName) {
      const accountIds = this.uniqueSelectedAccountIds
      if (accountIds.length < 1) {
        this.pageToasts.push('Please select atleast one account to create a device')
        return
      }
      this.entitiesBeingProcessed = accountIds
      this.entitiesProcessed = []
      this.creatingDevices = true
      this.performingAction = true
      this.entityInputModalProcessing = true
      for (const accountId of this.entitiesBeingProcessed) {
        const deviceId = Math.floor(Math.random() * 10000000000)// uuid.v4()
        await this.$store.dispatch('device/create', { deviceId: deviceId, accountId: accountId, deviceName: deviceName })
        this.entitiesProcessed.push(accountId)
        console.log(this.entitiesProcessed.length)
      }
      this.loadAccountsDevices()
      this.entityInputModalProcessing = false
      this.performingAction = false
      this.creatingDevices = false
      this.entityInputModalShowModal = false
    },
    initiateCreditAccounts () {
      const accountIds = this.uniqueSelectedAccountIds
      if (accountIds.length < 1) {
        this.pageToasts.push('Please select atleast one account to credit')
        return
      }
      this.entityInputModalTitle = 'Enter amount to credit'
      this.entityInputModalShowModal = true
      this.entityInputModalError = null
      this.entityInputModalPlaceholder = 'Credit amount'
      this.entityInputModalHelpText = 'Amount is required'
      this.entityInputModalProcessing = false
      this.entityInputModalProcessingText = 'Crediting amount'
      this.entityInputModalSubmitText = 'Credit Amount'
      this.entityInputModalType = 'CreditAccounts'
      this.entitiesBeingProcessed = []
      this.entitiesProcessed = []
    },
    async creditAccounts (creditAmount) {
      const accountIds = this.uniqueSelectedAccountIds
      if (accountIds.length < 1) {
        this.pageToasts.push('Please select atleast one account to credit')
        return
      }
      if (!creditAmount || isNaN(creditAmount)) {
        this.entityInputModalError = 'Please enter a valid amount'
        return
      }
      this.entitiesBeingProcessed = accountIds
      this.entitiesProcessed = []
      this.performingAction = true
      this.entityInputModalProcessing = true
      for (const accountId of this.entitiesBeingProcessed) {
        const creditLimit = await this.$store.dispatch('account/credit', { accountId: accountId, amount: creditAmount })
        this.entitiesProcessed.push(accountId)
        this.accounts.forEach(account => {
          if (account.accountId === accountId) {
            account.creditLimit = creditLimit
          }
        })
      }
      this.entityInputModalProcessing = false
      this.performingAction = false
      this.entityInputModalShowModal = false
    },
    toggleAccountSelection (account) {
      account.selected = !account.selected
      if (this.accounts.filter(account => !account.selected).length) {
        this.allAccountsSelected = false
      }
    },
    toggleAllAccountSelection () {
      this.allAccountsSelected = !this.allAccountsSelected
      this.accounts.forEach(account => { account.selected = this.allAccountsSelected })
    },
    handleEntityInputModalClose () {
      this.entityInputModalTitle = ''
      this.entityInputModalShowModal = false
      this.entityInputModalError = null
      this.entityInputModalPlaceholder = ''
      this.entityInputModalProcessing = false
      this.entityInputModalProcessingText = ''
      this.entityInputModalSubmitText = ''
      this.entityInputModalType = ''
    },
    openDeviceDemo () {
      const selectedAccounts = this.selectedDeviceAccounts
      if (selectedAccounts.length < 1) {
        this.pageToasts.push('Please select one account with a device present')
        return
      } else if (selectedAccounts.length > 1) {
        this.pageToasts.push('Please select only one account with a device')
        return
      }
      const selectedAccount = selectedAccounts[0]
      this.$router.push(`/account/${selectedAccount.accountId}/device/${selectedAccount.deviceId}`)
    },
    async handleEntityInputModalSubmitted (submittedValue) {
      switch (this.entityInputModalType) {
        case 'CreateAccount':
          await this.createAccount(submittedValue)
          return
        case 'CreateDevices':
          await this.createDevices(submittedValue)
          return
        case 'CreditAccounts':
          await this.creditAccounts(submittedValue)
      }
    },
    initiatePlanSubscription () {
      const accountIds = this.uniqueSelectedAccountIds
      if (accountIds.length < 1) {
        this.pageToasts.push('Please select atleast one account to perform subscription')
        return
      }
      this.showPlanSelectorModal = true
    },
    planSelectorModalClosed () {
      this.showPlanSelectorModal = false
    },
    overridesChanged (overrides) {
      this.planOverrides = overrides
    },
    async subscribeToPlan (selectedPlanVersionId) {
      console.log('Selected plan', selectedPlanVersionId)
      this.showPlanSelectorModal = false
      const accountIds = this.uniqueSelectedAccountIds
      if (accountIds.length < 1) {
        this.pageToasts.push('Please select atleast one account to perform subscription')
        return
      }
      if (!selectedPlanVersionId) {
        this.pageToasts.push('Please select a plan')
        return
      }
      this.entitiesBeingProcessed = accountIds
      this.performingAction = true
      this.entityInputModalProcessing = true
      for (const accountId of this.entitiesBeingProcessed) {
        const subscribedPlans = await this.$store.dispatch('account/subscribeToCurrentPlanVersion', { accountId: accountId, planVersionId: selectedPlanVersionId, overrides: this.planOverrides })
        this.entitiesProcessed.push(accountId)
        this.accounts.forEach(account => {
          if (account.accountId === accountId) {
            account.subscribedPlans = subscribedPlans
          }
        })
      }
      this.entityInputModalProcessing = false
      this.performingAction = false
    }
  },
  components: { TotBadge, TotButton, TotInputModal, PlanSelectorModal }
}
</script>
