export function buildReadableAllocationOrBalance (convertedEntity, entityType) {
  return Object.keys(convertedEntity).map(entityKey => {
    const entityValue = convertedEntity[entityKey]
    const entityType = getEntityType(entityKey)
    return {
      id: entityKey,
      name: getEntityName(entityKey),
      readableValue: getReadableEntityValue(entityType, entityValue),
      type: entityType,
      uuid: getEntityUUID(entityKey),
      value: entityValue
    }
  })[0]
}

export async function buildReadableAllocationsOrBalances (convertedEntities, entityType) {
  return convertedEntities.map(convertedEntity => buildReadableAllocationOrBalance(convertedEntity, entityType))
}

export function getEntityName (entityKey) {
  return entityKey.split('.')[0].split('-').map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`).join(' ')
}

export function getEntityType (entityKey) {
  if (entityKey.includes('voice-')) {
    return 'Voice'
  } else if (entityKey.includes('text-')) {
    return 'Text'
  } else if (entityKey.includes('data')) {
    return 'Data'
  }
}

export function getEntityUUID (entityKey) {
  const entityKeySplitted = entityKey.split('.')
  return entityKeySplitted[entityKeySplitted.length - 1]
}

export function getReadableEntityValue (entityType, entityValue) {
  if (entityValue === null) {
    return 'Unlimited'
  }
  if (entityType === 'Voice') {
    return (entityValue / 60).toString() + ' mins'
  } else if (entityType === 'Data') {
    return (entityValue / (1024 * 1024)).toString() + ' MB'
  } else {
    return (entityValue).toString() + ' messages'
  }
}

export async function getMonetaryBalance (balance) {
  return balance.Monetary.total
}

export async function getAllServiceBalances (balance) {
  const voiceKeys = Object.keys(balance).filter(objectKey => objectKey.startsWith('voice-'))
  const textKeys = Object.keys(balance).filter(objectKey => objectKey.startsWith('text-'))
  const dataKeys = Object.keys(balance).filter(objectKey => objectKey.startsWith('data'))
  // start with naive implementation that assumes just one balance
  let convertedBalances = []
  for (let i = 0; i < voiceKeys.length; i++) {
    // Add voice seconds to minutes conversion
    const newBalance = {
      [voiceKeys[i]]: balance[voiceKeys[i]].total
    }
    convertedBalances = [...convertedBalances, newBalance]
  }
  for (let i = 0; i < textKeys.length; i++) {
    const newBalance = {
      [textKeys[i]]: balance[textKeys[i]].total
    }
    convertedBalances = [...convertedBalances, newBalance]
  }
  for (let i = 0; i < dataKeys.length; i++) {
    // Add bytes to MB conversion
    const newBalance = {
      [dataKeys[i]]: balance[dataKeys[i]].total
    }
    convertedBalances = [...convertedBalances, newBalance]
  }
  return convertedBalances
}

export async function getAllServiceAllowances (allowances) {
  let convertedAllowances = []
  try {
    for (let i = 0; i < allowances.length; i++) {
      if (allowances[i].unit && allowances[i].unit.balanceName !== 'undefined') {
        const newAllowance = {
          [allowances[i].unit.balanceName]: allowances[i].unit.periodAllowance
        }
        convertedAllowances = [...convertedAllowances, newAllowance]
      }
    }
    return convertedAllowances
  } catch (e) {
    console.log('Undefined balance name found')
    console.log(e)
    return convertedAllowances
  }
}
