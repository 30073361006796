<template>
  <div style="height: 100vh !important;">
    <div class="d-flex flex-column h-100">
      <div>
        <MainNav />
      </div>
      <CModal
        :backdrop="true"
        :centered="true"
        alignment="center"
        :visible="isDeviceModalShown"
        @close="handleDeviceIdModalClose"
      >
        <CModalHeader>
          <CModalTitle>Enter Device ID for Demo</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CAlert class="p-2" color="warning" v-if="deviceIdError">
            <font-awesome-icon icon="fa-solid fa-circle-exclamation" /> {{deviceIdError}}
          </CAlert>
          <CFormInput
            :disabled="isDeviceLoading"
            ref="deviceIdInput"
            type="text"
            placeholder="Device ID"
            v-model="selectedDeviceId"
          />
        </CModalBody>
        <CModalFooter>
          <TotButton :loading="isDeviceLoading" @button-click="loadDevice()">
            <template #loading>Loading Device&nbsp;</template>
            <template #initial>Use Device</template>
          </TotButton>
        </CModalFooter>
      </CModal>
      <CModal
        backdrop="static"
        :visible="$store.state.accountDeviceError"
      >
        <CModalHeader>
          <CModalTitle>Plan Error</CModalTitle>
        </CModalHeader>
        <CModalBody>Your currently selected plan has unsupported features! Please publish a new plan and plan version and refresh the page to demo.</CModalBody>
      </CModal>
      <CContainer fluid class="px-0 flex-grow-1" style="max-height: calc(100% - 52px) !important;" v-if="!isDeviceLoaded && isDeviceLoading && !isDeviceModalShown">
        <div class="fs-2 py-5 px-4 text-center">
          Loading Device <CSpinner />
        </div>
      </CContainer>
      <CContainer fluid class="px-0 flex-grow-1" style="max-height: calc(100% - 52px) !important;" v-if="isDeviceLoaded">
        <CRow class="h-100">
          <CCol xl="4" class="h-100 d-none d-xl-block">
            <div class="phone-header fs-5 text-start fw-semibold">
              <div>
                <CLink
                  class="text-primary text-decoration-none"
                  href="#"
                  @click="this.$router.push({ name: 'Accounts' })"
                >
                  <font-awesome-icon icon="fa-solid fa-arrow-left" />
                  &nbsp;
                  <span>BACK TO ACCOUNTS</span>
                </CLink>
              </div>
              <div class="py-3">
                <CRow class="text-center">
                  <CCol>
                    Account <TotBadge class="large-badge" :copyValue="selectedAccountId">{{ accountIdText }}</TotBadge>
                  </CCol>
                  <CCol>
                    Device <TotBadge class="large-badge" :copyValue="selectedDeviceId">{{ deviceIdText }}</TotBadge>
                  </CCol>
                </CRow>
              </div>
            </div>
            <PhoneDemo
              v-if="deviceHasActivePlans"
              :deviceId="selectedDeviceId"
              :minuteSelection="minuteSelection"
              :smsSelection="smsSelection"
              v-model:callednumber="callednumber"
              @change-minute="changeMinuteSelection"
              @change-sms="changeSmsSelection"
            />
          </CCol>
          <CCol xl="4" class="d-xl-none">
            <div class="phone-header fs-5 text-start fw-semibold">
              <div>
                <CLink
                  class="text-primary text-decoration-none"
                  href="#"
                  @click="this.$router.push({ name: 'Accounts' })"
                >
                  <font-awesome-icon icon="fa-solid fa-arrow-left" />
                  &nbsp;
                  <span>BACK TO ACCOUNTS</span>
                </CLink>
              </div>
              <div class="py-3">
                <CRow class="text-center">
                  <CCol>
                    Account <TotBadge class="large-badge" :copyValue="selectedAccountId">{{ accountIdText }}</TotBadge>
                  </CCol>
                  <CCol>
                    Device <TotBadge class="large-badge" :copyValue="selectedDeviceId">{{ deviceIdText }}</TotBadge>
                  </CCol>
                </CRow>
              </div>
            </div>
            <PhoneDemo
              v-if="deviceHasActivePlans"
              :deviceId="selectedDeviceId"
              :minuteSelection="minuteSelection"
              :smsSelection="smsSelection"
              v-model:callednumber="callednumber"
              @change-minute="changeMinuteSelection"
              @change-sms="changeSmsSelection"
            />
          </CCol>
          <CCol xl="8" class="d-flex flex-column border-start bg-white h-100">
            <div class="d-flex flex-row px-4 py-3">
              <div class="flex-grow-1">
                <!--<div class="d-flex flex-row text-start">
                  <div class="fw-bolder d-grid gap-1">
                    <div>Plan Name</div>
                    <div>Version Name</div>
                    <div>Last Modified</div>
                  </div>
                  <div class="flex-grow-1 ps-4 d-grid gap-1">
                    <div>{{ planName }}</div>
                    <div>{{ planVersionName }}</div>
                    <div>{{ lastModifiedAt }}</div>
                  </div>
                </div>-->
                <div class="d-flex flex-row text-start pe-3"
                  v-for="(planSubscription, key) in allActivePlanSubscriptions"
                  :key="key"
                  >
                  <div :class="['fw-bolder', 'd-grid', 'gap-1', { 'border-top': key !== 0 }]">
                    <div>Plan Name</div>
                    <div>Version Name</div>
                  </div>
                  <div :class="['flex-grow-1', 'ps-4', 'd-grid', 'gap-1', { 'border-top': key !== 0 }]">
                    <div>{{ planSubscription.planName }}</div>
                    <div>{{ planSubscription.planVersionName }}</div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <CDropdown color="primary" togglerText="Resubscribe Plan" :disabled="provisioningNewPlan">
                    <CDropdownToggle component="a" color="primary">Resubscribe Plan</CDropdownToggle>
                    <CDropdownMenu>
                      <CDropdownItem
                        v-for="(planSubscription, key) in allActivePlanSubscriptions"
                        :key="key" href="#" @click="resubscribePlan(planSubscription.planSubscriptionId, planSubscription.planVersionId)">{{ planSubscription.planName }}</CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>
                </div>
                <div class="pt-1">
                  <CBadge v-if="hasValidPlan" color="success">Days left in plan: {{daysLeftInPlan}}</CBadge>
                  <CBadge v-else color="danger">No Valid Plan Found</CBadge>
                </div>
              </div>
            </div>
            <div class="flex-grow-1 d-flex flex-column" style="max-height: calc(100% - 112px) !important;" v-if="deviceHasActivePlans">
              <div class="h-50">
                <ShowAccountBox
                  v-if="!$store.state.allPlanVersionAllocations"
                  :accountId="selectedAccountId"
                  :deviceId="selectedDeviceId"
                />
                <BalancesTable
                  v-if="$store.state.allPlanVersionAllocations"
                  :accountId="selectedAccountId"
                  :deviceId="selectedDeviceId"
                />
              </div>
              <div class="h-50" style="overflow: scroll;">
                <LogBox :logLines="currentLogLines" />
              </div>
            </div>
            <div v-else class="py-5 px-4">
              <CAlert color="warning">
                <font-awesome-icon icon="fa-solid fa-circle-exclamation" /> No active plan found. Please provision a plan first using the above button.
              </CAlert>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import { GRAPHQL_DEVICE_NOT_FOUND_ERROR_CODE } from '../utils/constants'

import MainNav from '@/components/MainNav.vue'
import LogBox from '@/components/LogBox.vue'
import ShowAccountBox from '@/components/ShowAccountBox.vue'
import BalancesTable from '@/components/BalancesTable.vue'
import PhoneDemo from '@/components/PhoneDemo.vue'
import TotBadge from '@/components/common/tot-badge.vue'
import TotButton from '@/components/common/tot-button.vue'

export default {
  components: {
    LogBox,
    MainNav,
    ShowAccountBox,
    BalancesTable,
    PhoneDemo,
    TotButton,
    TotBadge
  },
  name: 'PhoneView',
  props: {
    deviceId: {
      type: String,
      required: false
    },
    accountId: {
      type: String,
      required: false
    }
  },
  beforeRouteUpdate (to, from) {
    console.log('Router updae', to, from)
    this.initializeDemo()
  },
  beforeRouteLeave (to, from) {
    console.log('leave')
    this.reset()
  },
  data () {
    return {
      callednumber: '',
      minuteSelection: 5,
      smsSelection: 5,
      provisioningNewPlan: false,
      selectedAccountId: null,
      selectedDeviceId: null,
      loadedDevice: false,
      loadingDevice: false,
      showDeviceIdModal: false,
      deviceIdError: null
    }
  },
  computed: {
    currentLogLines () {
      const currentLogLines = this.$store.state.deviceLogs[this.selectedDeviceId]
      if (currentLogLines === undefined) {
        return []
      }
      return currentLogLines
    },
    allActivePlanSubscriptions () {
      const serviceBalances = this.$store.state.device.demoAccountBalance.serviceBalances
      const subscriptionIds = []
      const subscriptions = []
      for (const serviceBalance of serviceBalances) {
        if (subscriptionIds.includes(serviceBalance.planSubscriptionId)) {
          continue
        }
        subscriptionIds.push(serviceBalance.planSubscriptionId)
        subscriptions.push({
          planVersionId: serviceBalance.planVersionId,
          planVersionName: serviceBalance.planVersionName,
          planSubscriptionId: serviceBalance.planSubscriptionId,
          planName: serviceBalance.planName,
          planId: serviceBalance.planId
        })
      }
      return subscriptions
    },
    accountIdText () {
      return this.selectedAccountId.split('-')[0]
    },
    deviceHasActivePlans () {
      return this.$store.state.device.demoAccountPlans && this.$store.state.device.demoAccountPlans.length
    },
    deviceIdText () {
      return this.selectedDeviceId.split('-')[0]
    },
    hasValidPlan () {
      return this.deviceHasActivePlans && this.$store.state.device.demoAccountPlans.filter(plan => !plan.planExpired).length
    },
    daysLeftInPlan () {
      const plan = this.devicePlan
      if (plan) {
        return plan.daysLeftInPlan
      }
      return ''
    },
    devicePlan () {
      return (this.$store.state.device.demoAccountPlans || []).filter(plan => plan.daysLeftInPlan !== null)[0]
    },
    isDeviceLoaded: {
      get () {
        return this.loadedDevice
      },
      set (newValue) {
        this.loadedDevice = newValue
      }
    },
    isDeviceLoading: {
      get () {
        return this.loadingDevice
      },
      set (newValue) {
        this.loadingDevice = newValue
      }
    },
    planName () {
      return (this.devicePlan ? this.devicePlan.planName : '-')
    },
    planVersionName () {
      return (this.devicePlan ? this.devicePlan.planVersionName : '-')
    },
    lastModifiedAt () {
      return (this.devicePlan ? moment(this.devicePlan.modifiedAt).format('MMM D, YYYY') : '-')
    },
    isDeviceModalShown: {
      get () {
        return this.showDeviceIdModal
      },
      set (newValue) {
        this.showDeviceIdModal = newValue
      }
    }
  },
  mounted () {
    this.initializeDemo()
  },
  methods: {
    handleDeviceIdModalClose () {
      this.isDeviceModalShown = false
      this.$router.push({ name: 'Accounts' })
    },
    changeMinuteSelection (value) {
      console.log('called numner', this.callednumber)
      this.minuteSelection = value
    },
    changeSmsSelection (value) {
      this.smsSelection = value
    },
    async initializeDemo () {
      if (this.accountId && this.deviceId) {
        this.selectedAccountId = this.accountId
        this.selectedDeviceId = this.deviceId
      } else {
        this.selectedAccountId = null
        this.selectedDeviceId = null
      }
      if (this.selectedAccountId && this.selectedDeviceId) {
        this.isDeviceModalShown = false
        this.loadDevice()
      } else {
        this.isDeviceModalShown = true
        await this.$nextTick()
        this.$refs.deviceIdInput.$el.parentElement.querySelector('input').focus()
      }
    },
    reset () {
      this.$store.dispatch('device/resetDemoDevice')
      if (this.$store.state.deviceLogs && this.$store.state.deviceLogs[this.selectedDeviceId]) {
        this.$store.state.deviceLogs[this.selectedDeviceId] = []
      }
      this.callednumber = ''
      this.minuteSelection = 5
      this.smsSelection = 5
      this.provisioningNewPlan = false
      this.selectedAccountId = null
      this.selectedDeviceId = null
      this.isDeviceLoaded = false
      this.isDeviceLoading = false
      this.isDeviceModalShown = false
      this.deviceIdError = null
      this.$store.dispatch('resetEdrs')
    },
    async loadDevice () {
      if (!this.selectedDeviceId) {
        this.deviceIdError = 'Please enter the device ID'
        return
      }
      this.isDeviceLoading = true
      try {
        await this.$store.dispatch('device/getDemoDevice', { deviceId: this.selectedDeviceId })
      } catch (err) {
        if (err.errorCode && err.errorCode === GRAPHQL_DEVICE_NOT_FOUND_ERROR_CODE) {
          this.deviceIdError = 'Device not found, please check the ID'
          this.isDeviceLoading = false
          return
        }
      }
      this.selectedAccountId = this.$store.state.device.demoDevice.account.id
      await this.$nextTick()
      await this.$nextTick()
      this.isDeviceLoading = false
      this.isDeviceLoaded = true
      this.isDeviceModalShown = false
    },
    async reloadBasicPlanData () {
      this.provisioningNewPlan = true
      await this.$nextTick()
      await this.$store.dispatch('device/provisionNewPlanDemoDevice')
      this.provisioningNewPlan = false
    },
    async resubscribePlan (planSubscriptionId, planVersionId) {
      this.provisioningNewPlan = true
      await this.$nextTick()
      await this.$store.dispatch('device/resubscribePlan', { planSubscriptionId, planVersionId })
      this.provisioningNewPlan = false
    }
  }
}
</script>

<style scoped>
h3 {
  color: #fff;
}
h4 {
  margin-top: 5px;
  color: #fff;
}
.row {
  --bs-gutter-x: 1 rem;
  --cui-gutter-x: 1 rem;
}
.phone-header {
  margin: 30px auto;
  width: 400px;
}
.phone-header a:hover span{
  text-decoration: underline !important;
}
body {
  background: #f4f6fc;
}
body:after {
  content: 'Made with ❤️ by Adiel Hercules';
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  background-color: #3F436B;
  color: #fff;
  opacity: 0.6;
}
.large-badge {
  min-width: 115px;
}
</style>
